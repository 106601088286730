import React, {useEffect} from 'react';
import './Login.css';
import {UseLogin} from '../../store/login/state';
import {login} from '../../store/login/action';

const Wrapper = () => (
    <div>
        <Header />
        <Auth />
    </div>
);

const Header = () => (
    <section className="hero is-danger is-bold">
        <div className="hero-body">
            <div className="container">
                <h1 className="title">
                    Lead Sales Motor
                </h1>
                <h2 className="subtitle">
                    Automatiza tus presentaciones de Google Slide
                </h2>
            </div>
        </div>
    </section>
);

const LoginButton = ({ icon, name, onClick }) => (
    <div className="field">
        <p className="control button is-medium is-danger" style={{ width: '300px' }} onClick={onClick}>
      <span className="icon">
        <i className={`fa fa-${icon}`} aria-hidden="true"></i>
      </span>
            <span>{`Sign In With ${name}`}</span>
        </p>
    </div>
);

const Auth = (props) => {

    const [state, dispatch] = UseLogin();

    const handleLogin = () => login(dispatch);

    useEffect(() => {
        if (state.credentials.uri) {
            window.location.href = state.credentials.uri;
        }
    }, [state])

    return (
        <section className="section">
            <div className="has-text-centered">
                <LoginButton icon="google" name="Google" onClick={handleLogin} />
            </div>
        </section>
    );
}

function Login() {
    return (
      <div className="app">
          <Wrapper />
      </div>
  );
}

export default Login;
