import React, {useContext, useReducer} from "react";
import reducer from './reducer';
import {LoginContext} from './context';

const UseLogin = () => {
    const context = useContext(LoginContext);
    if (context === undefined) {
        throw new Error('UseLoginmust be used within a Provider')
    }
    const {state, dispatch} = context;
    return [state, dispatch];
};

const LoginState = ({children}) => {
    const initialState = {
        urlProvider: undefined,
        loading: false,
        error: {
            enabled: false,
            message: ""
        },
        credentials: {
            uri: '',
            access_token: '',
            refresh_token: ''
        }
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch}
    return (
        <LoginContext.Provider value={value}>
            {children}
        </LoginContext.Provider>
    );
};

export {LoginState, UseLogin};