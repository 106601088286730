import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import './Slide.css';
import {MergeState, UseMerge} from '../../store/merge/state';
import {merge} from '../../store/merge/action';
import {getTokens} from '../../store/login/action';
import {UseLogin} from '../../store/login/state';

const colorsRandom = [
    'is-black', 'is-dark', 'is-light', 'is-white', 'is-primary', 'is-link', 'is-info', 'is-success', 'is-warning', 'is-danger'
];

const randomColor = () => colorsRandom[Math.floor(Math.random() * colorsRandom.length)];;

const Wrapper = () => (
    <div>
        <Header />
        <HowToUse />
        <Template />
    </div>
);

const Header = () => (
    <section className="hero is-danger is-bold">
        <div className="hero-body">
            <div className="container">
                <h1 className="title">
                    Lead Sales Motor
                </h1>
                <h2 className="subtitle">
                    Automatiza tus presentaciones de Google Slide
                </h2>
            </div>
        </div>
    </section>
);

const HowToUse = () => {
    return (
        <div className="columns is-centered">
            <div className="column is-half">
                <div className="card mt-2">
                    <div className="card-content">
                        <p className="title">
                            Instrucciones de uso
                        </p>
                        <p className="subtitle">
                            Seleccione el nombre de la presentaci&oacute;n a generar y añada tantas presentaciones de
                            Google Slide como necesite (+), es importante, obtener el identificador del documento por medio
                            de la url
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
};

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Template = (props) => {
    let query = useQuery();
    let navigate = useNavigate();
    const [state, dispatch] = UseMerge();
    const [stateLogin, dispatchLogin] = UseLogin();
    const [request, setRequest] = useState({
        name: '',
        editors: '',
        viewers: '',
        args: {
            projectName: "Lead Sales Motor",
            clientName: "Imagemaker S.A",
            objective: "Objetivos es vender",
            requirements: "Los requerimientos son...",
            solution: {
                image: "https://avatars.githubusercontent.com/u/13573645?u=47a629b3c12f8996586cb0d18968e9c8df8f779f&v=4",
                description: "Esto es una descripcion"
            },
            duration: {
                months: "12"
            },
            price: {
                uf: "3.500"
            },
            team: [{
                role: "Arquitecto de Software",
                count: "1",
                asignation: "10%"
            }]
        },
        templates: [{
            id: '1Xqp09SzkuJ8VYu4yJQqrAifuEe21wK3J-WI2fA3DomU',
            slides: '',
            tags: 'base'
        }]
    });

    const handleOnPlusInTeam = () => setRequest({...request, args: {...request.args, team: [...request.args.team, {
            role: '',
            count: '',
            asignation: '',
        }]}});

    const handleOnMinusInTeam = () => {
        if (request.args.team.length > 1) {
            const team = request.args.team;
            team.pop();
            setRequest({...request, args: {...request.args, team: [...team]}});
        }
    }

    const handleOnPlus = () => setRequest({...request, templates: [...request.templates, {
        id: '',
        slides: '',
        tags: '',
        }]});

    const handleOnMinus = () => {
        if (request.templates.length > 1) {
            const templates = request.templates;
            templates.pop();
            setRequest({...request, templates: [...templates]});
        }
    }

    const handleToChangeTeam =  (index, event, property) => {
        const team = [...request.args.team];
        team[index][property] = event.target.value;
        setRequest({...request, args: {...request.args, team}});
    };

    const handleToChangeTemplate =  (index, event, property) => {
        const templates = [...request.templates];
        templates[index][property] = event.target.value;
        setRequest({...request, templates});
    };

    const handleToChangeData = (event, property) => {
        setRequest({...request, args: {...request.args, [property]: event.target.value}});
    };

    const handleToChangeDataFromParent = (event, propertyParent, propertyChild) => {
        setRequest({...request, args: {...request.args, [propertyParent]: {...request.args[propertyParent], [propertyChild]: event.target.value}}});
    };

    const handleToChangeWPermission = (event) => {
        setRequest({...request, editors: event.target.value});
    };

    const handleToChangeRPermission = (event) => {
        setRequest({...request, viewers: event.target.value});
    };

    const handleToChangeName = (event) => {
        setRequest({...request, name: event.target.value});
    };

    const handleOnSubmit = (event) => {
        event.preventDefault();
        console.log("request", request);
        merge(dispatch, {
            ...request,
            editors: request.editors.length > 0 ? request.editors.split(',') : [],
            viewers: request.viewers.length > 0 ? request.viewers.split(','): [],
            templates: request.templates.map(element => {
                return {
                    id: element.id,
                    slides: element.slides.length > 0 ? element.slides.split(',').map(element => parseInt(element)) : [],
                    tags: element.tags.length > 0 ? element.tags.split(',') : []
                };
            })
        }, {access_token: stateLogin.credentials.access_token, refresh_token: stateLogin.credentials.refresh_token});
    }

    const OutputSlides = () => state.presentation.map((data, index) => {
        return (
            <div key={index} className="mt-2">
                <span className={"tag ".concat(randomColor())}>{data.name}</span> <br/>
                <a href={data.uri} target="_blank" rel="noreferrer">{data.uri}</a>
            </div>
        )
    });

    useEffect(() => {
        const token = query.get("code");
        if (token === undefined || token.length === 0) {
            navigate('/login', { replace: true });
            return;
        }
        getTokens(dispatchLogin, token);
    }, [navigate, query, dispatchLogin])

    return (
        <section className="section">
            <div className="columns is-centered">
                <div className="column is-half">
                    <form onSubmit={handleOnSubmit}>
                        <div className="field">
                            <label className="label">Nombre del documento a generar</label>
                            <div className="control">
                                <input className="input" type="text" placeholder="Nombre Presentaci&oacute;n"
                                       onChange={handleToChangeName} value={request.name}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Nombre del Proyecto</label>
                            <div className="control">
                                <input className="input" type="text" placeholder="Nombre Proyecto"
                                       onChange={(e) => handleToChangeData(e, 'projectName')} value={request.args.projectName}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Nombre Cliente</label>
                            <div className="control">
                                <input className="input" type="text" placeholder="Nombre Cliente"
                                       onChange={(e) => handleToChangeData(e, 'clientName')} value={request.args.clientName}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Objetivos</label>
                            <div className="control">
                                <textarea className="textarea" placeholder="Objetivos"
                                       onChange={(e) => handleToChangeData(e, 'objective')} value={request.args.objective}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Requerimientos</label>
                            <div className="control">
                                <textarea className="textarea" placeholder="Requerimientos"
                                       onChange={(e) => handleToChangeData(e, 'requirements')} value={request.args.requirements}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Soluci&oacute;n</label>
                            <div className="control">
                                <input className="input" type="text" placeholder="Imag&eacute;n"
                                       onChange={(e) => handleToChangeDataFromParent(e, 'solution', 'image')} value={request.args.solution.image}/>
                                <textarea className="textarea mt-2" placeholder="Descripci&oacute;n"
                                          onChange={(e) => handleToChangeDataFromParent(e, 'solution', 'description')} value={request.args.solution.description}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Duraci&oacute;n (meses)</label>
                            <div className="control">
                                <input className="input" type="text" placeholder="Duraci&oacute;n en meses"
                                       onChange={(e) => handleToChangeDataFromParent(e, 'duration', 'months')} value={request.args.duration.months}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Precio (UF)</label>
                            <div className="control">
                                <input className="input" type="text" placeholder="Precio en UF"
                                       onChange={(e) => handleToChangeDataFromParent(e, 'price', 'uf')} value={request.args.price.uf}/>
                            </div>
                        </div>
                        <div className="field mt-2">
                            <label className="label">Team</label>
                            <div className="control buttons">
                                <button className="button" type="button" onClick={handleOnPlusInTeam} disabled={state.loading}>
                                    <span className="icon is-small">
                                      <i className="fas fa-plus"></i>
                                    </span>
                                </button>
                                <button className="button" type="button" onClick={handleOnMinusInTeam} disabled={request.args.team.length <=1 || state.loading}>
                                    <span className="icon is-small">
                                      <i className="fas fa-minus"></i>
                                    </span>
                                </button>
                            </div>
                            {
                                request.args.team.map((data, index) => {
                                    return (
                                        <div key={index}>
                                            <div className="field">
                                                <label className="label">Rol del Colaborador #{index + 1}</label>
                                                <div className="control">
                                                    <input className="input" type="text" placeholder="eg: Arquitecto de Software"
                                                           onChange={event => handleToChangeTeam(index, event, 'role')} value={data.role}/>
                                                </div>
                                            </div>
                                            <div className="field mt-2">
                                                <label className="label">Cantidad de Colaboradores #{index + 1}</label>
                                                <div className="control">
                                                    <input className="input" type="text" placeholder="eg: 10"
                                                           onChange={event => handleToChangeTeam(index, event, 'count')} value={data.count}/>
                                                </div>
                                            </div>
                                            <div className="field mb-2">
                                                <label className="label">Asignación (%) #{index + 1}</label>
                                                <div className="control">
                                                    <input className="input" type="text" placeholder="eg: 100%"
                                                           onChange={event => handleToChangeTeam(index, event, 'asignation')} value={data.asignation}/>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className="field mt-2">
                            <label className="label">Slides</label>
                            <div className="control buttons">
                                <button className="button" type="button" onClick={handleOnPlus} disabled={state.loading}>
                                    <span className="icon is-small">
                                      <i className="fas fa-plus"></i>
                                    </span>
                                </button>
                                <button className="button" type="button" onClick={handleOnMinus} disabled={request.templates.length <=1 || state.loading}>
                                    <span className="icon is-small">
                                      <i className="fas fa-minus"></i>
                                    </span>
                                </button>
                            </div>
                            {
                                request.templates.map((data, index) => {
                                    return (
                                        <div key={index}>
                                            <div className="field">
                                                <label className="label">Identificador de PPT en Google Slide #{index + 1}</label>
                                                <div className="control">
                                                    <input className="input" type="text" placeholder="Id Presentaci&oacute;n"
                                                           onChange={event => handleToChangeTemplate(index, event, 'id')} value={data.id}/>
                                                </div>
                                            </div>
                                            <div className="field mb-2">
                                                <label className="label">Tags (Permite seleccionar slides)</label>
                                                <div className="control">
                                                    <input className="input" type="text" placeholder="eg: base, sales, strategy"
                                                           onChange={event => handleToChangeTemplate(index, event, 'tags')} value={data.tags}/>
                                                </div>
                                            </div>
                                            <div className="field mb-2">
                                                <label className="label">Slides a seleccionar</label>
                                                <div className="control">
                                                    <input className="input" type="text" placeholder="eg: 1, 2, 3, 4"
                                                           onChange={event => handleToChangeTemplate(index, event, 'slides')} value={data.slides}/>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className="field">
                            <label className="label">Permiso Edición Documento</label>
                            <div className="control">
                                <input className="input" type="text" placeholder="persona@dominio, persona2@dominio"
                                       onChange={handleToChangeWPermission} value={request.editors}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Permiso Lectura Documento</label>
                            <div className="control">
                                <input className="input" type="text" placeholder="persona@dominio, persona2@dominio"
                                       onChange={handleToChangeRPermission} value={request.viewers}/>
                            </div>
                        </div>
                        <div className="field is-grouped">
                            <div className="control buttons">
                                <button className="button is-link" onClick={handleOnSubmit} type="submit" disabled={state.loading}>Submit</button>
                            </div>
                        </div>
                    </form>
                    {state.loading && (
                        <progress className="progress is-large is-info mt-2" max="100">50%</progress>
                    )}
                    {state.presentation.length > 0 && (
                        <article className="message is-info mt-2">
                            <div className="message-body">
                                <OutputSlides />
                            </div>
                        </article>
                    )}
                </div>
            </div>
        </section>
    );
}

function Slide() {
    return (
      <MergeState>
          <div className="app">
              <Wrapper />
          </div>
      </MergeState>
  );
}

export default Slide;
