import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import './App.css';
import Login from "./login/Login";
import Slide from "./slide/Slide";
import {LoginState} from "../store/login/state";

function App() {
    return (
      <LoginState>
          <div className="app">
              <Router>
                  <div className='container.is-widescreen'>
                      <LoginState>
                          <Routes>
                              <Route path="/slides" element={<Slide />} />
                              <Route path="/" element={<Login />} />
                              <Route path="*" element={<Login />} />
                          </Routes>
                      </LoginState>
                  </div>
              </Router>
          </div>
      </LoginState>
  );
}

export default App;
