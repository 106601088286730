const mergeReducer = (state, action) => {
    switch (action.type) {
        case 'MERGE': {
            return {...state, presentation: [...state.presentation, action.payload], error: {enabled: false, message: ""}}
        }
        case 'MERGE_ERROR': {
            return {...state, error: {enabled: true, message: action.payload}}
        }
        case 'SET_LOADING': {
            return {...state, loading: action.payload}
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};

export default mergeReducer;