import React, {useContext, useReducer} from "react";
import reducer from './reducer';
import {MergeContext} from './context';

const UseMerge = () => {
    const context = useContext(MergeContext);
    if (context === undefined) {
        throw new Error('UseMerge must be used within a Provider')
    }
    const {state, dispatch} = context;
    return [state, dispatch];
};

const MergeState = ({children}) => {
    const initialState = {
        presentation: [],
        loading: false,
        error: {
            enabled: false,
            message: ""
        },
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch}
    return (
        <MergeContext.Provider value={value}>
            {children}
        </MergeContext.Provider>
    );
};

export {MergeState, UseMerge};