import * as Repository from '../../repository/security';

const setLoading = (dispatch, status) =>
    dispatch({ type: "SET_LOADING", payload: status });

const login = async (dispatch) => {
    setLoading(dispatch, true);
    return new Promise((resolve, reject) => Repository.login().then(async (result) => {
        await dispatch({type: 'LOGIN', payload: result.data})
        resolve(result.data);
    }).catch(async (error) => {
        await dispatch({type: 'LOGIN_ERROR', payload: error})
        console.log(`Has ocurred an error, check de console ${error}`);
        reject(error);
    })).finally(() => setLoading(dispatch, false));
};

const getTokens = async (dispatch, authCode) => {
    setLoading(dispatch, true);
    return new Promise((resolve, reject) => Repository.getTokens(authCode).then(async (result) => {
        await dispatch({type: 'TOKEN', payload: result.data})
        resolve(result.data);
    }).catch(async (error) => {
        await dispatch({type: 'TOKEN_ERROR', payload: error})
        console.log(`Has ocurred an error, check de console ${error}`);
        reject(error);
    })).finally(() => setLoading(dispatch, false));
};

export {login, setLoading, getTokens};