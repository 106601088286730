const loginReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN': {
            return {...state, credentials: {...state.credentials, ...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'LOGIN_ERROR': {
            return {...state, error: {enabled: true, message: action.payload}}
        }
        case 'TOKEN': {
            return {...state, credentials: {...state.credentials, ...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'TOKEN_ERROR': {
            return {...state, error: {enabled: true, message: action.payload}}
        }
        case 'SET_LOADING': {
            return {...state, loading: action.payload}
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};

export default loginReducer;