import * as Repository from '../../repository/presentation';

const setLoading = (dispatch, status) =>
    dispatch({ type: "SET_LOADING", payload: status });

const merge = async (dispatch, request, tokens)=> {
    setLoading(dispatch, true);
    Repository.merge(request, tokens).then(result => {
        dispatch({type: 'MERGE', payload: result.data});
    }).catch(error => {
        dispatch({type: 'MERGE_ERROR', payload: error})
        console.log(`Has ocurred an error, check de console ${error}`);
    }).finally(() => setLoading(dispatch, false));
};

export {merge, setLoading};